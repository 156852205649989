import React from 'react';
import ReactMarkdown from 'react-markdown';
import { MainContent } from '../../views/Goldman/markdown';

//Custom components
import HeroBanner from '../../components/HeroBanner';
import BackgroundImg from '../../assets/portfolio/goldman/hero-goldman.webp';
import ImageCaption from '../../components/ImageCaption';
import VideoCaption from '../../components/VideoCaption';
import TwoUp from '../../components/TwoUp';

//images
import PersonaOne from '../../assets/portfolio/goldman/gs-persona-1.webp';
import PersonaTwo from '../../assets/portfolio/goldman/gs-persona-2.webp';
import JourneyMap from '../../assets/portfolio/goldman/breakaway-journey.webp';
import LandingConcept from '../../assets/portfolio/goldman/landing-concept.webp';
import Carousel from '../../assets/portfolio/goldman/solutions-carousel.webm';


const ConnectBanner = {
  title: 'Goldman Sachs',
  description: 'Advisor Solutions service design',
  background: BackgroundImg,
}

export default function Goldman(props) {
  return (
    <main class="page-shell">

      <HeroBanner title={ConnectBanner.title} description={ConnectBanner.description} background={ConnectBanner.background}/>

      <section class="main-wrapper mb-64">
        <a class="cta short" href="https://advisorsolutions.gs.com/home" target="_blank" rel="noreferrer">View live site</a>
        <ReactMarkdown>{MainContent}</ReactMarkdown>
      </section>
        <TwoUp
          alignment="text-right double"
          alt="An breakaway persona for Goldman Sachs." 
          image={PersonaTwo}
          imageTwo={PersonaOne} 
          caption={[<h2>Persona creation</h2>,<p>Ultimately, I pared down to two core persona from a list of five. It was important to distinguish the executive and the breakway because they have very different needs. An executive is likely looking to switch their custodian for their existing business, while a breakway is looking to establish their own advisory service and needs to be discreet until all their deals are completed.</p>]}/>

        <ImageCaption alignment="text-left" alt="a journey map showing the journey for a breakaway advisor." image={JourneyMap} caption={[<h2>Journey maps</h2>,<p>One of several journeys that we put together to align the customer journey against specific sections of the new site. We used modular building blocks of customer types, actions, and touchpoints.</p>]} />

        <ImageCaption alignment="text-right" alt="a wireframe of an early homepage concept." image={LandingConcept} caption={[<h2>Visual exploration</h2>,<p>Early concepts for the homepage explored a daring horizontal scroll to match with the daring move into a new market for Goldman Sachs. It was later determined that it was more important to surface the 3 persona sooner so users could self-identify the services most relevant to them.</p>]} />

        <VideoCaption alignment="text-left" video={Carousel} caption={[<h2>Interaction design</h2>,<p>I created several bespoke animations for the marketing site for post-MVP. I prototoyped these first in Figma, then in live code.</p>]} />



    </main>
  );
};