
export const SectionOne = `

## Overview

Bose AR is the first-of-its-kind audio augmented reality platform, combining the power of next-gen Bose audio products (i.e. Bose Frames) with innovative mobile apps. Together, they create astonishing real-world experiences and new ways to travel, exercise, learn, and play.

Cantina supported Bose with their new AR platform and product launch with Strategy, Design and Development expertise, which has lead to new platform integrations and third party development for other brands and organizations.

## My role

As the principal designer and strategist for Bose AR, my responsibilities included:

* Generative interviews and co-design with Bose AR Creator tool users
* Audio AR research and testing to develop new notification technology
* Collaborating with product management and executive leadership to develop platform strategy and business models
* Breaking down silos and providing strategic design leadership to three separate design teams
`;



export const SectionTwo = `

## Outcomes

Some of the outputs from my work on this client were:

* Wireframes and style sheets for the Bose AR Creator tool
* Personas, Jobs to be Done, and user journey maps
* Product roadmaps
* Collaborative wiki housing all user research knowledge for the team
`;