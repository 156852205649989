import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return(
		  <footer>
		    <div class='inside-wrapper'>
		    	<div class="col-left">
		    		<h2>Contact</h2>
		    		<a href="mailto:info@coreyaroth.com?subject=Let%27s%20collaborate">info@coreyaroth.com</a>
		    	</div>
		    	<div class="col-right">
		    	<h2>Follow</h2>
		    	<ul>
		    		<li><a href="https://twitter.com/coreyaroth">Twitter</a></li>
		    		<li><a href="http://lnkd.in/JeyYFU">Linkedin</a></li>
		    		<li><a href="https://codepen.io/coreyroth/">Codepen</a></li>
		    	</ul>
		    	</div>
		    </div>
		  </footer>
    )
  }
}

export default Footer;