import React from 'react';

export default function ImageCaption(props) {
  const {alignment, image, alt, caption} = props;
  return(
    <figure class={alignment}>

    <div>
      <img src={image} alt={alt}/>
    </div>
      <figcaption>{caption}</figcaption>
    </figure>
    )
}