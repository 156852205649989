import NewportImg from '../../assets/portfolio/newport/featured-newport.webp';
import BoseImg from '../../assets/portfolio/headphones/featured-headphones.webp';
import CovetrusImg from '../../assets/portfolio/covetrus/featured-covetrus.webp';
import GoldImg from '../../assets/portfolio/goldman/featured-goldman.webp';
import AudubonImg from '../../assets/portfolio/audubon/featured-audubon.webp';
import PunchImg from '../../assets/portfolio/punchbowl/featured-punchbowl.webp';
import AxiosImg from '../../assets/portfolio/axios/featured-axios.webp';
import NasdaqImg from '../../assets/portfolio/nasdaq/featured-nasdaq.webp';


const GalleryData = [
	{
		link: '/audubon',
		client: 'The Audubon Society',
		title: 'Migratory Bird Explorer',
		description: 'Accessible tools for exploring migratory bird data to support conservation against climate change.',
		background: AudubonImg,
	}, {
		link: '/punchbowl',
		client: 'Punchbowl News',
		title: 'People. Power. Politics.',
		description: 'Research and product strategy for inclusive political news communities on Capitol Hill.',
		background: PunchImg,
	},
 	{
		link: '/goldman',
		client: 'Goldman Sachs',
		title: 'Advisor Solutions',
		description: 'Design support for the Goldman Sach entry into registered independant advisor services.',
		background: GoldImg,
	},
	{
		link: '/nasdaq',
		client: 'Nasdaq',
		title: 'Corporate Governance',
		description: 'Content strategy and design guidance to support new business lines for an established brand.',
		background: NasdaqImg,
	},
	{
		link: '/newport',
		client: 'Newport Folk',
		title: 'All about the music',
		description: 'Innovative audio-first experiences to keep festival-goers focused on what matters: the artists.',
		background: NewportImg,
	}, {
		link: '/bosear',
		client: 'Bose AR',
		title: 'Emerging technologies',
		description: 'Experience strategy and innovative audio-first experiences with a legendary brand.',
		background: BoseImg,
	}, {
		link: '/covetrus',
		client: 'Covetrus',
		title: 'Design enablement',
		description: 'New product innovation and design strategy for an animal health client.',
		background: CovetrusImg,
	},
	{
		link: '/axios',
		client: 'Axios',
		title: 'Publishing & Design Strategy',
		description: 'Product strategy and design leadership to support UX maturity at a cutting-edge news organization.',
		background: AxiosImg,
	},
]

export default GalleryData;