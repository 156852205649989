import React from 'react';

export default function TwoUp(props) {
  const {alignment, image, imageTwo, alt, caption} = props;
  return(
    <figure class={alignment}>

      <img src={image} alt={alt}/>
      <img src={imageTwo} alt={alt}/>

      <figcaption>{caption}</figcaption>
    </figure>
    )
}