export const MainContent = `

## Project Summary

The Audubon Society is devoted to the conservation of birds and their habitats across the globe. They approached Postlight with an opportunity to partner on a new data platform. The platform provides never-before-seen insight into the behaviors of migratory birds in the Western Hemisphere, and the impact that climate change will have on them in the near future. We worked with Audubon on their product strategy, as well as the design and development of the entire platform.


## My Role

* Design lead—facilitate co-design sessions with the client and uncover opportunities to simplify the experience while retaining the full insights of the original dataset for a variety of users from PhDs to home hobbyists.
* Conducted an accessibility audit with increasing understandability and usability for keyboard-navigating and screen reader-using users as a key focus.
* Work with Audubon to understand and prioritize features to determine an MVP launch that would drive action on climate change, while considering a featureful tool that provides powerful insights for conservationists.

`;
