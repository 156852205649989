import React from 'react';
import ReactMarkdown from 'react-markdown';
import {SectionOne, SectionTwo, SectionThree, SectionFour, SectionFive } from '../../views/Punchbowl/markdown';

//Custom components
import HeroBanner from '../../components/HeroBanner';
import ImageCaption from '../../components/ImageCaption';
import TwoUp from '../../components/TwoUp';
import ThreeUp from '../../components/ThreeUp';

//Images
import BackgroundImg from '../../assets/portfolio/punchbowl/hero-punchbowl.webp';
import ServiceBlueprint from '../../assets/portfolio/punchbowl/pbn-service-blueprint.webp';
import FreeTrial from '../../assets/portfolio/punchbowl/free-trial.webp';
import Wires from '../../assets/portfolio/punchbowl/pbn-wires.webp';
import Persona from '../../assets/portfolio/punchbowl/persona.webp';
import PersonaTwo from '../../assets/portfolio/punchbowl/persona-two.webp';
import PersonaThree from '../../assets/portfolio/punchbowl/persona-three.webp';

const ConnectBanner = {
  title: 'Punchbowl News',
  description: 'Research & design for community in politics',
  background: BackgroundImg,
}

export default function Punchbowl(props) {
  return (
    <main class="page-shell">

      <HeroBanner title={ConnectBanner.title} description={ConnectBanner.description} background={ConnectBanner.background}/>

      <section class="main-wrapper mb-64">
        <a class="cta short" href="https://punchbowl.news/" target="_blank" rel="noreferrer">View live site</a>
        <ReactMarkdown>{SectionOne}</ReactMarkdown>
        <ReactMarkdown>{SectionTwo}</ReactMarkdown>
      </section>

          <TwoUp
          alignment="text-left double"
          alt="Punchbowl service blueprint for attending an event" 
          image={ServiceBlueprint}
          imageTwo={FreeTrial} 
          caption={[<h2>Service blueprints</h2>, <p>One of several service blueprints I made for the team to help guide them in understanding new roles and processes they would need to support their new service. Shown here is a service blueprint for events and another for a free trial.</p>]}/>

      <section class="main-wrapper mb-64">
        <ReactMarkdown>{SectionThree}</ReactMarkdown>
      </section>

          <ImageCaption
          alignment="text-right"
          alt="Excerpt from a slide deck displaying research findings" 
          image={Wires} 
          caption={[<h2>The future of community</h2>, <p>I delivered the research findings in a deck to the team. Beyond data and recommendations, I also provided some high-level wireframes to help the team visualize what the future state of their online community could look like.</p>]}/>
    
      <section class="main-wrapper mb-64">
        <ReactMarkdown>{SectionFour}</ReactMarkdown>
      </section>

          <ThreeUp
          alignment="text-left triple"
          alt="Punchbowl personas" 
          image={Persona}
          imageTwo={PersonaTwo}
          imageThree={PersonaThree} 
          caption={[<h2>Persona creation</h2>, <p>I delivered three different persona to Punchbowl News, each classified by their orientation to political news and their committment in the idea of a Punchbowl News community. Some community members are defined both by their passion for politics and their immediate need for access to news about Capitol Hill. For others, they view themselves as belonging to a community that's committed to civil, neutral discourse in politics.</p>]}/>


      <section class="main-wrapper mb-64">  
        <ReactMarkdown>{SectionFive}</ReactMarkdown>
      </section>
    </main>
  );
};