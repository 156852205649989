import BackgroundImg from '../../assets/portfolio/headphones/hero-bose.webp';

import SlideOne from '../../assets/portfolio/headphones/ar-workbench-map.webp';
import SlideTwo from '../../assets/portfolio/headphones/boxy.webp';
import SlideThree from '../../assets/portfolio/headphones/service-blueprint.webp';
import SlideFour from '../../assets/portfolio/headphones/dev-api.webp';
import SlideFive from '../../assets/portfolio/headphones/Categories.webp';

export const BoseBanner = {
  title: 'Bose AR',
  description: 'No-code platform for innovative audio-first experiences',
  background: BackgroundImg,
}


export const BoseSlideshow = [
  {
    image: SlideOne,
    text: 'My initial three months on the project involved creating concepts for the project at various steps of the roadmap, often multiple years worth of features. This was to provide a vision for the new project and to give the junior designers I led a jumping-off point for their immediate design work. Here shows the "Workbench", where audio AR creators can place audio pins in 3D space over time.',
  }, {
    image: SlideTwo,
    text: 'An alternative view of the first screen, showing the audio pin in XYZ space.',
  }, {
    image: SlideThree,
    text: 'A service blueprint we built to help the broader organization understand how their structure would need to shift to support this new service.',
  }, {
    image: SlideFour,
    text: 'Screens showing how third-party developers can manage API keys for their apps to integrate with Bose AR.',
  }, {
    image: SlideFive,
    text: 'Some (very) high-level wireframes I created to help underscore the importance of categorizing audio AR content within apps.',
  },
]