import React from 'react';
import {NewportBanner} from '../../components/App/newport-data';
import NoddyVid from '../../assets/portfolio/newport/Noddy.webm';
import ReactMarkdown from 'react-markdown';
import {SectionOne, SectionTwo, SectionThree} from '../../views/Newport/markdown';

//Custom components
import HeroBanner from '../../components/HeroBanner';
import ImageCaption from '../../components/ImageCaption';

//Images
import SlideOne from '../../assets/portfolio/newport/listen-ios.webp';
import SlideTwo from '../../assets/portfolio/newport/up-here-next.webp';


const Newport = props => {
  return (
      <main class="page-shell">

        <HeroBanner title={NewportBanner.title} description={NewportBanner.description} background={NewportBanner.background}/>

        <section class="main-wrapper mb-64">
          <ReactMarkdown>{SectionOne}</ReactMarkdown>
        </section>

          <ImageCaption
              alignment="text-center"
              alt="Screen flows for an iOS app." 
              image={SlideOne} 
              caption={[<h2>User Journeys</h2>,<p>Screen flows created in partnership with my five-person design and engineering team. They juggled multiple Bose and Newport Folk Festival stakeholders effortlessly.</p>]}/>

          <ImageCaption alignment="text-center"
            alt="an audio dialog flowchart."
            image={SlideTwo}
            caption={[<h2>Audio design</h2>,<p>One of the audio dialog flowcharts I created for the team to guide implementation.</p>]}/>
 
        <section class="main-wrapper mb-64">
          <ReactMarkdown>{SectionTwo}</ReactMarkdown>
        </section>

          <figure class="text-right">
            <div>
            <video loop="" preload="auto" tabindex="0" controls>
                <source src={NoddyVid} type="video/webm"/>
                It looks like your web browser doesn't support the WEBM video format. 
            </video>
            </div>
            <figcaption><h2>Pitching new concepts</h2><p>As part of our design process, we pitched some additional concepts for interactivity to Bose. This is an example video for a hypothetical "head-nodding" game called Noddy festival-goers could play with their friends.</p></figcaption>
          </figure>

        <section class="main-wrapper mb-64">
          <ReactMarkdown>{SectionThree}</ReactMarkdown>
        </section>
      </main>
  );
};

export default Newport;