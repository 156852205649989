export const Markdown =  `

  ## Role & team
  
  * Self: design lead & client therapist
  * Ed: content strategy
  * Patrick: design systems
  * Tina: user experience
  * Client team: Director of UX, various VP-level business stakeholders

  ## The problem
  
  * Nasdaq has a big tent of business lines that all need to represent themselves publicly in a smart way.
  * Postlight designed and built a robust design system in Drupal to provide them with endless combinations of building blocks.
  * My role was to help the client articulate their needs and GTM strategy while coordinating across content, design systems, and junior designers.

  ## Process
  
  * Biweekly review cycles
  * Worked on IA for each sub-site, wireframes for the individual pages
  * Regular communication with the design system team about what was possible and what we would need to table for now

  ## Results
  
  * 181% increase in conversion rate
  * 103% increase in keyword visibility
  * 83% increase in pages per session
  * Broke through organizational trauma from being locked into their old CMS
  * Surprise and delight from the client


  `;

export default Markdown;