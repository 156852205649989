import React from 'react';
import ReactMarkdown from 'react-markdown';
import {SectionOne, SectionTwo, SectionThree, SectionFour} from '../../views/Axios/markdown';

//Custom components
import HeroBanner from '../../components/HeroBanner';
import ImageCaption from '../../components/ImageCaption';

//images
import BackgroundImg from '../../assets/portfolio/axios/hero-axios.webp';
import SelectImage from '../../assets/portfolio/axios/select.webp';
import InfoImage from '../../assets/portfolio/axios/info-model.webp';
import FiveBigThings from '../../assets/portfolio/axios/five-big-things.webp';
import PhoneConcepts from '../../assets/portfolio/axios/phone-concepts.webp';

const ConnectBanner = {
  title: 'Axios design leadership',
  description: 'Service design & design strategy',
  background: BackgroundImg,
}

export default function Axios(props) {

  return (
      <main class="page-shell">

        <HeroBanner title={ConnectBanner.title} description={ConnectBanner.description} background={ConnectBanner.background}/>

        <section class="main-wrapper mb-64">
          <a class="cta short" href="https://axios..com/" target="_blank" rel="noreferrer">View live site</a>
          <ReactMarkdown children={SectionOne}/>
          <ReactMarkdown children={SectionTwo}/>
        </section>

      <ImageCaption
          alignment="text-left"
          alt="A diagram illustration the various attributes and actions involved in creating an article." 
          image={InfoImage} 
          caption={[<h2>Iinformation architecture 101</h2>,<p>As people join and leave a team, it's easy for tribal knowledge to get lost. We quickly realized we needed to diagram the current metadata, including attributes and actions, associated with an article.</p>]}/>

        <ImageCaption
          alignment="text-right"
          alt="A diagram with different states of select options." 
          image={SelectImage} 
          caption={[<h2>Design system documentation</h2>, <p>There was no consistency in the interaction patterns for forms. I created guidelines for form validations, as well as documentation for every atomic form element.</p>]}/>

        <ImageCaption alignment="text-left"
          alt="Concepts for a scrolling article feed"
          image={FiveBigThings}
          caption={[<h2>Five Big Things</h2>,<p>I created multiple concepts for new ways to discover content, like a curated module for Axios' 5 Big Things and a merchandized showcase of recent articles from related topics. This also included concepts for creating these collections in the CMS.</p>,<p>Along the way, we redesigned the navigation for Axios.com to focus on what mattered to readers instead of reflecting business priorities.</p>]} />

        <ImageCaption alignment="text-right"
          alt="a series of phone concepts illustrating ways to surface tags on a news site."
          image={PhoneConcepts}
          caption={[<h2>Discoverability</h2>, <p>Other concepts that I created included: a tastemakers section for onboarding, adding visible topic tags to articles with dynamically populated topic pages, more robust search capabilities, a daily brief curated by the newsrooms based on reader topic interests, and a dynamic subscription modal for articles that recommended newsletters based on the current article.</p>]}/>

        <section class="main-wrapper mb-64">
          <ReactMarkdown children={SectionThree}/>
          <ReactMarkdown children={SectionFour}/>
        </section>
      </main>
  );
};
