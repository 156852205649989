import React from 'react';
import {CovetrusBanner} from '../../components/App/covetrus-data';
import CovetrusCards from '../../assets/portfolio/covetrus/covetrus-cards.webp';
import Dox from '../../assets/portfolio/covetrus/covetrus-documentation.pdf';
import ReactMarkdown from 'react-markdown';
import {SectionOne, SectionTwo} from '../../views/Covetrus/markdown';
import SlideOne from '../../assets/portfolio/covetrus/checkout-prototype.webm';
import SlideTwo from '../../assets/portfolio/covetrus/covetrus-journey.webp';

//Custom components
import HeroBanner from '../../components/HeroBanner';
import ImageCaption from '../../components/ImageCaption';
import VideoCaption from '../../components/VideoCaption';

const Covetrus = props => {
  return (
      <main class="page-shell">
        <HeroBanner title={CovetrusBanner.title} description={CovetrusBanner.description} background={CovetrusBanner.background}/>

        <section class="main-wrapper mb-64">
          <ReactMarkdown>{SectionOne}</ReactMarkdown>

          <a class="cta short" href={Dox} target="_blank" rel="noreferrer">Documentation sample</a>

        </section>

        <VideoCaption alignment="text-left phone" video={SlideOne} caption={[<h2>In-clinic checkout</h2>,<p>This is a short video showing a prototype I made of an in-clinic checkout app. Over the course of a month, I researched, designed, and built a Flinto prototype for testing with both veterinary clinics and pet owners.</p>]}/>

        <ImageCaption alignment="text-right" alt="" image={SlideTwo} caption={[<h2>Pet owner communication preferences</h2>,<p>This XY matrix of pet owner preferences for communication was created as part of a short 2-week research sprint.</p>]}/>

        <section class="main-wrapper ptb-0 mb-64">
          <ReactMarkdown>{SectionTwo}</ReactMarkdown>
        </section>

        <ImageCaption alignment="text-left"
          alt=""
          image={CovetrusCards}
          caption={[<h2>Design system documentation</h2>,<p>I spent over a year working on standardizing design patterns across the various Covetrus properties, and then aligning documentation with the new Covetrus brand.</p>]} />
      </main>
  );
};

export default Covetrus;