
export const SectionOne = `

## Overview

Covetrus is an industry-leading software company in the animal health space. My team has been embedded with the internal product organization since August 2019, working to "bolster their ranks" while leveling up their design skills and raising the awareness of user-centered, strategic design.

## My role

As a lead designer and strategist, my responsibilities included:

* Working with product management to establish a model for product innovation and delivering UX in an Agile environment.
* Leading the development of a cross-functional, cross-product design system.
* Creating prototypes for faster feedback loops with customers.
* Defining UX metrics to enable tangible business outcomes from the UX process.
`;



export const SectionTwo = `

## Outcomes

Some of the outputs from my work on this client were:

* Creating a standard for design artifacts such as annotated wireframes, styleguides, interactive prototypes, and product requirements documents.
* Delivering across the double diamond from research and strategy, to testable prototypes, to final designs.
* Collaborating with client partners on design team staffing models and UX team maturity and growth.
`;