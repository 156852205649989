import React from 'react';

export default function ThreeUp(props) {
  const {alignment, image, imageTwo, imageThree, alt, caption} = props;
  return(
    <figure class={alignment}>
      <img src={image} alt={alt}/>
      <img src={imageTwo} alt={alt}/>
      <img src={imageThree} alt={alt}/>
      <figcaption>{caption}</figcaption>
    </figure>
    )
}