export const SectionOne = `  

   *I'm currently redesigning my site to be more accessible. In the open, no less! If you see something unusual, feel free to [send me an email](mailto:info@coreyaroth.com?subject=Site%20Accessibility).*

   I'm currently a Senior UX Designer at Amazon supporting Seller Infrastructure & Strategic Initiatives. We build innovative platform-scale capabilities to support businesses in selling on Amazon. 

   Previously, I was a Managing Lead Product Designer at [Postlight](https://postlight.com), a digital product studio in New York City. Before that, I worked in innovation consulting at [Cantina](https://cantina.co/), where I led the IoT research group and worked with clients to build high-performing research & design teams. I also spent several years working in healthcare at the CVSHealth Innovation Lab and Cardinal Health, as well as freelance consulting in the public and third sectors.

   I am a contributing writer for the UX Collective, and have spoken at conferences such as [a11ybos](https://a11y-bos.org/) and the [Connected Health Conference](https://www.pchalliance.org/connected-health-conference). I am a member of the Service Design Network. I am also a supporter of Design Museum Everywhere, and was a guest for a podcast episode on [Curiosity in Design](https://designmuseumfoundation.org/034-ux-design-curiosity/).

   I studied at Central Ohio Technical College and Shawnee State University, and continue to be a strong supporter of the opportunities afforded to many by technical colleges and state universities. I currently serve on the advisory board for [Rasmussen University School of Design](https://guides.rasmussen.edu/design).

   **My current focus is on inclusive design, design strategy, and using service design methods to advance innovation.**


   When I’m not designing, I enjoy studying languages (French and Chinese), ultrarunning (my favorite distance is the 50k), live music, [photography](https://500px.com/coreyaroth), and anything that gets me outside.

   ## My clientele:
`;

export const SectionTwo = `
   ## Apps I've shipped
   
   I've shipped work on Bose Connect, Bose Music, CVS Pharmacy, Newport Folk Festival, Axios, and ScriptConnect.

   ## Writings and talks
   
   I've given talks on [frameworks for inclusive products](https://cantina.co/a-sea-change-in-accessibility-a11ybos-2019/) at a11ybos, and spoken about the future of connnected health at Connected Health Conference. Whether it’s attending Leading Design, conducting user research with game developers at PlayNYC, or taking sketch notes at UXStrat, I’m grateful for every opportunity to chart the future of design.

   ## Other projects
   
   * [Experience Strategy Method Cards](https://methodcards.cantina.co)
   * [Use Your Methods](https://www.useyourmethods.com)
   * Rasmussen University School of Design Advisory Board Member
   * Boston Marathon Fundraiser 2020
   * Pelotonia Fundraiser 2017
   * Service Design Network member
`;