import React from 'react';
import Headshot from '../../assets/corey-roth-transparent.png';
import Nascar from '../../components/Nascar';
import Resume from '../../assets/corey-roth-ux-resume.pdf';
import ReactMarkdown from 'react-markdown';
import {SectionOne, SectionTwo} from '../../views/About/markdown';

export default function About(props) {
  return (
    <div class="main about">
        <div class="about-wrapper mb-64">
          <div class="photo-wrap">
            <img src={Headshot} alt="This is Corey Roth. This site is about him. He's wearing a red-and-white plaid shirt and smiling slightly."/>

            <ul class="unstyled-list link-list">
              <li><a href="http://lnkd.in/JeyYFU" target="_blank" rel="noreferrer">Linkedin</a></li>
              <li><a href={Resume} target="_blank"  rel="noreferrer">Resume</a></li>
              <li><a href="mailto:info@coreyaroth.com?subject=Let's%20collaborate">Email</a></li>
            </ul>
          </div>
          <div class="text-wrap">
            <h1>I’m Corey. I’m a design leader who codes and does strategy.</h1>
            <ReactMarkdown linkTarget="_blank">{SectionOne}</ReactMarkdown>

            <Nascar/>

            <ReactMarkdown linkTarget="_blank">{SectionTwo}</ReactMarkdown>

          </div>
        </div>
    </div>
  );
};