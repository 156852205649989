import React from 'react';
import ReactMarkdown from 'react-markdown';
import { MainContent } from '../../views/Audubon/markdown';
import HeroBanner from '../../components/HeroBanner';
import BackgroundImg from '../../assets/portfolio/audubon/hero-audubon.webp';
import VideoCaption from '../../components/VideoCaption';
import ImageCaption from '../../components/ImageCaption';

//images
import MapWires from '../../assets/portfolio/audubon/map-wires.webp';
import Overlay from '../../assets/portfolio/audubon/overlay.webp';
import ExploreMap from '../../assets/portfolio/audubon/audubon-explore.webp';
import MobileMap from '../../assets/portfolio/audubon/map-mobile.webp';
import Timeline from '../../assets/portfolio/audubon/timeline.webm';
import AudubonIA from '../../assets/portfolio/audubon/audubon-ia.webp';


export const BirdBanner = {
  title: 'The Audubon Society',
  description: 'Migratory Bird Explorer accessibility & responsive design',
  background: BackgroundImg,
}

export default function About(props) {
  return (
    <main class="page-shell">

    <HeroBanner
      title={BirdBanner.title}
      description={BirdBanner.description}
      background={BirdBanner.background}/>

      <section class="main-wrapper mb-64">
        <ReactMarkdown>{MainContent}</ReactMarkdown>
      </section>

      <ImageCaption alignment="text-right"
        alt=""
        image={AudubonIA}
        caption={[<h2>Information architecture</h2>,<p>Through customer interviews and stakeholder conversations, we reorganized the app around the primary verbs - explore maps, discover birds, and take action. This jobs-to-be-done approach took Audubon out of their internal ways of thinking about the data and conservation challenges.</p>]}/>

      <ImageCaption alignment="text-left" alt="a wireframe of an early homepage concept." image={MapWires} caption={[<h2>Concepts</h2>,<p>Early wireframes relied heavily on mobile interaction patterns and a minimal interface. We later learned that our older customer base needed more affordances for interaction.</p>]} />

      <ImageCaption alignment="text-right" alt="a wireframe of an early homepage concept." image={Overlay} caption={[<h2>Accessibility improvements</h2>, <p>We did a pass for accessibility on the entire interface. This included standardizing interactions and adding more affordances, not just color contrast and screen reader behaviors.</p>]} />

      <ImageCaption alignment="text-left" alt="a wireframe of an early homepage concept." image={ExploreMap} caption={[<h2>Improved search</h2>,<p>I split Locations and Bird Species into separate searches to better match users' mental models. I also made several visual improvements to add clarity with labels, and redesigned the timeline view of migration data to make it clearer what the user was viewing.</p>]} />

      <ImageCaption alignment="text-right"
        alt="visual designs for a data visualization app."
        image={MobileMap}
        caption={[<h2>Responsive design</h2>,<p>In addition to my accessibility audit, I did all of the reponsive behavior for the app. This was challenging in sections, as the app is data-rich and required lots of explanation.</p>,<p>I settled on embracing the scroll, and designing pleasing typographic layouts that encouraged discovering new content.</p>]}/>

      <VideoCaption alignment="text-left"
        video={Timeline}
        caption={[<h2>Migratory paths</h2>,<p>My accessibility work included updates to animations and visuals for migratory birth paths. I adjusted colors and animation playback to help make the data around where birds spent the summer and winter months clearer, and reduced the prominance of data points for abundance to keep the focus on the migration pathways.</p>]}/>

    </main>
  );
};