import React from 'react';

//Custom components
import Slideshow from '../../components/Slideshow';
import HeroBanner from '../../components/HeroBanner';
import ReactMarkdown from 'react-markdown';
import {SectionOne, SectionTwo} from '../../views/BoseAR/markdown';
import {BoseBanner, BoseSlideshow} from '../../components/App/ar-data';
import StyleGuide from '../../assets/portfolio/headphones/Style Guide.pdf';

const BoseAR = props => {
  return (
      <main class="page-shell">

        <HeroBanner
          title={BoseBanner.title}
          description={BoseBanner.description}
          background={BoseBanner.background}/>
        
         <section class="main-wrapper mb-64">
              <ReactMarkdown>{SectionOne}</ReactMarkdown>

              <a class="cta short" href={StyleGuide} target="_blank" rel="noreferrer">See the style guide</a>
          </section>

        <Slideshow slides={BoseSlideshow} />

        <section class="main-wrapper mb-64">
          <ReactMarkdown>{SectionTwo}</ReactMarkdown>
        </section>
      </main>
  );
};

export default BoseAR;