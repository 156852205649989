export const MainContent = `

## Summary

With the acquisition of a new company (something that Goldman Sachs does not frequently do), we had the opportunity to help the new business put their best foot forward.

We focused on developing personae and identifying key desire paths through the site by interviewing internal stakeholders and current customers, then moved into concepting several visually lush options for the client to pick from.

Once we'd aligned on the new branding, we scaffolded out a design system and content strategy built on Wordpress so Goldman had a solution that would work for the near-term, but also scale for future needs.

## Role & team

* Self: Product designer (discovery & design)
* Sarah: Product manager
* Several engineers
* Client team: Head of business and various VP/C-level stakeholders

`;
