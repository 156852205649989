import React from 'react';
import ReactMarkdown from 'react-markdown';
import Markdown from '../../views/Nasdaq/markdown';

//Custom components
import HeroBanner from '../../components/HeroBanner';
import ImageCaption from '../../components/ImageCaption';

//images
import BackgroundImg from '../../assets/portfolio/nasdaq/hero-nasdaq.webp';
import Sitemap from '../../assets/portfolio/nasdaq/sitemap.webp';
import ComponentImg from '../../assets/portfolio/nasdaq/components.webp';
import ScreenImg from '../../assets/portfolio/nasdaq/screens.webp';

const ConnectBanner = {
  title: 'Nasdaq Corporate Governance',
  description: 'Design & content strategy',
  background: BackgroundImg,
}

export default function Nasdaq(props) {

  return (
      <main class="page-shell">

        <HeroBanner title={ConnectBanner.title} description={ConnectBanner.description} background={ConnectBanner.background}/>

        <section class="main-wrapper mb-64">
          <a class="cta short" href="https://www.nasdaq.com/solutions/governance/boardvantage" target="_blank" rel="noreferrer">View live site</a>
          <ReactMarkdown children={Markdown}/>
        </section>
          <ImageCaption
              alignment="text-right"
              alt="A sitemap skeleton for a marketing site." 
              image={Sitemap} 
              caption={[<h2>Sitemap creation</h2>,<p>Over the course of several workshops with the client, we aligned on a consistent sitemap that we could repeat across Nasdaq's various SaaS solutions. We identified it was important to align this with their marketing funnel, and that they needed to build new content to support customer past the point of purchase.</p>]}/>

          <ImageCaption alignment="text-left"
            alt=""
            image={ComponentImg}
            caption={[<h2>Designed to scale</h2>,<p>A major pain point for the client was the inflexibility of their current CMS. As part of a larger design team, I worked to build components that would suit a variety of business needs and documented specific attributes that needed to be flexible.</p>]}/>
 
          <ImageCaption alignment="text-right"
            alt=""
            image={ScreenImg}
            caption={[<h2>Building the site</h2>,<p>Our overall goal was to tell a consistent story for a major brand while making it easier for customers to find solutions that fit their needs.</p>]}/>
      </main>
  );
};
