export const SectionOne = `

## Summary
I was on the Punchbowl project for the beginning and end. At the beginning of the project, I spent a month working with the client and the PM on researching what their long-term product strategy should be. This included exploring early ideas around community. We ultimately developed several personae and service blueprints from this research.

At the tail end of the project, I came back to assist in building out and documenting components for engineering so that Punchbowl had a reusable component library for building out future parts of their site.

`;

export const SectionTwo = `

## Role & team

* Self: UX research & product strategy
* Sarah: Product strategy & management
* Isar: UX design
* Kay + Matt: Engineering
* Client team: Founder's circle, including head of Product & Growth

`;

export const SectionThree = `


## The problem

Punchbowl News was founded by four breakaway journalists from Politico. The day they announced their new venture was January 6th, 2020 and they were reporting live from Capitol Hill. They quickly earned thousands of loyal subscribers. 

Punchbowl News came to Postlight wanting to know if they had an opportunity to build a community. They were looking to scale for the first time since launching.

Through research, we were able to uncover potential new touchpoints & features to explore as they sought to grow their audience. We identified opportunities for new community manager and product manager roles, and defined processes for those new hires as we built our their new community hub.

`;

export const SectionFour = `


## Process

* 5 ethnographic interviews
* 2 qual-quant surveys
* Two weeks of stakeholder interviews
* 1 week of desk research

`;

export const SectionFive = `


## Results

* New website
* Roadmap for incumbent PM
* Areas for future research
* Design artifacts to support future priorities



`;
