import React from 'react';
import Slider from "react-slick";
import PrevArrow from '../assets/svg/previous-arrow.svg';
import NextArrow from '../assets/svg/next-arrow.svg';

function Arrow(props) {
  let className = props.type === "next" ? "slick-next" : "slick-prev";
  let imagePath = props.type === "next" ? NextArrow : PrevArrow;
  className += " slick-arrow";
  return (
    <button className={className} onClick={props.onClick}>
      <img src={imagePath} alt=''/>
    </button>
  );
}

function InnerMedia(props) {
  let mediaType = props.type === "video" ? "video" : "image";
  if (mediaType === "video") {
      return (
        <video loop="" preload="auto" tabindex="0" controls><source src={props.url} type="video/mp4"/></video>
      );
  } else {
      return (
        <img src={props.url} alt={props.text}/>
      );
  }
}

export default function Slideshow(props) {
  const {slides} = props;
  return (

  <Slider 
    dots={true}
    infinite={false}
    dotsClass= 'slideshow-dots'
    nextArrow={<Arrow type="next" />}
    prevArrow={<Arrow type="prev" />}
    class="slideshow">
    {slides.map(slide => (
      <div class="slideshow-inner">
         <div class="inner-media">
            <InnerMedia type={slide.category} url={slide.image} text={slide.text}/>
        </div>
        <div class="inner-caption">
          <p>{slide.text}</p>
        </div>
      </div>
      ))}  
  </Slider>
  

  )
}
