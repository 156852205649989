
export const SectionOne = `

## Overview

The Newport Folk Festival™ had a simple goal for its mobile app: focus on the music and help people find their favorite artists. Cantina design and built an intelligent iOS and Android app to reflect life at the festival in real time. Opened 60,000 times, the new app resulted in major gains in efficiency, decreased task time, and increased user happiness.

In 2019, Newport Folk, Cantina, and Bose partnered to bring a new first-of-its-kind audio experience to the festival. Bose AR—built into Bose Frames—is audio augmented reality that lets you connect to your digital world while staying present in the physical one. With Bose Frames and Newport Folk, users heard:

* Custom reminders 10 minutes before your  favorites play
* Who’s playing next at any stage
* Alerts for surprise events—before other folks do
* Select tracks recorded live at Newport—enhanced with 360° audio.
`;



export const SectionTwo = `

## My role

As design lead and a researcher, my responsibilities included:

* Wireframes and visual design for key app features
* Design and user flows for Bose AR integration
* Assist in the creation of a generative and evalutative research plan
* User interviews, synthesis, and presentation of findings
`;



export const SectionThree = `

## Outcomes

Some of the outputs from my work on this client were:

* Wireframes and style sheets
* User flow maps and visual design assets
* Product roadmaps and key findings for Newport Folk and Bose
`;