import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import './NavBar.scss';

const NavBar = () => {
  return (
      <header>
        <nav class="top-nav">
           <Link to="/home" aria-label="Return to the homepage.">
            <svg xmlns="http://www.w3.org/2000/svg" class="top-nav-img" viewBox="0 0 311.4 360.3" data-toggle="nav-list">
              <g id="80be4cc7-f965-4703-9285-79a5266584f3" data-name="Layer 2">
                <g id="d4419e96-2b97-4c5e-be5a-2b02c00112dc" data-name="Layer 1">
                  <path class="logo-path" d="M226.3,235.9h-.1a119.2,119.2,0,0,0,33.5-23.5c1.4-1.4,2.7-3,4-4.5h0A120,120,0,0,0,285.4,173a126.3,126.3,0,0,0,9.2-48.3V124a125.1,125.1,0,0,0-9.2-48.1,123.8,123.8,0,0,0-25.7-39.4A119.1,119.1,0,0,0,220.3,9.8a120.6,120.6,0,0,0-30-8.3L186.5,1,178.6.3h-.7L169.6,0H0V360.3H79.3V245h129l-31.7,52.2,42.1,62.9h92.7Zm-6-111.8q0,23.2-15.7,37.1t-45,13.9H79.3V71.6H158q29.3,0,45.8,12.9T220.3,123Z"/>
                </g>
              </g>
            </svg>
          </Link>

          <ul class="nav-list">
            <li><NavLink activeClassName="active" to="/home">My Work</NavLink></li>
            <li><NavLink activeClassName="active" to="/about">About Me</NavLink></li>
            <li><a href="https://coreyaroth.medium.com/" target="_blank" rel="noreferrer">Writings</a></li>
         	</ul>
        </nav>
      </header>
  );
};

export default NavBar;