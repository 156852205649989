export const SectionOne =  `

  ## Role & team

  * Self: Interim design director, design lead for CMS
  * Maya: Design buddy
  * 25+ engineers
  * 1 Product Manager
  * Client team: VP of design, 4 junior designers, CTO, and a large product and engineering org

`;

export const SectionTwo =  `


  ## The problem

  * Axios had a missing middle in their design team structure: a VP of design, 4 junior designers, and a massive backlog of work.
  * The CTO brought me in because the team was missing deadlines and she didn’t feel comfortable having them present to executives.
  * I coached and managed the team while they filled the Director of Design role. This included individual skills growth and helping the team to develop consistent processes that fit for them while improving efficiency.
  * I also led my own workstream for the Axios publishing tool migration while picking up tickets to help take pressure off the team.

`;

export const SectionThree =  `


  ## Process

  * Biweekly design reviews
  * Weekly pair design sessions and 1:1’s
  * Workshops with design, dev, and product to define new ways of working
  * Figma restructure, migration to component variants and structure projects

`;

export const SectionFour =  `


  ## Results

  Overall, the VP of Design and CTO were delighted by the team's improved ability to deliver quality work at speed with the new agile team structure.

  * Team better equipped to articulate design decisions
  * More research activities performed – interviews, tree testing, and card sorting instead of testing visual concepts
  * Publishing tool project exists as a model showroom that the team can use to guide structuring their own projects
  * Massive uptick in design team velocity

  `;
