import React from 'react';
import { Link } from "react-router-dom";

export default function CaseStudy(props) {
  const {link, client, description, background} = props;
  return(
  <article>
     <Link to={link} class='home-section'>
          <div class='home-section_img'>
            <img src={background}  alt=''/>
          </div>
          <div class='home-section_text'>
            <h2>{client}</h2>
            <p>{description}</p>
            <p class="pointer">View project ⟶</p>
          </div>
      </Link>
    </article>
    )
}