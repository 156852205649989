import React from 'react';
import Fade from 'react-reveal/Fade';

//Image imports
import BoseLogo from '../assets/logos/bose-logo.png';
import NewportLogo from '../assets/logos/nff-logo.png';
import CovetrusLogo from '../assets/logos/covetrus-logo.png';
import IrobotLogo from '../assets/logos/irobot-logo.png';
import AtlanticLogo from '../assets/logos/atlantic-council-logo.png';
import CardinalLogo from '../assets/logos/cardinal-health-logo.png';
import CVSLogo from '../assets/logos/cvs-logo.png';
import GoldmanLogo from '../assets/logos/goldman-logo.png';
import AxiosLogo from '../assets/logos/axios-logo.png';
import AudubonLogo from '../assets/logos/audubon-logo.png';
import PunchbowlLogo from '../assets/logos/punchbowl-logo.png';
import NasdaqLogo from '../assets/logos/nasdaq-logo.png';


const LogoParade = [
	{
		path: BoseLogo,
		alt: 'Bose',
	}, {
		path: NewportLogo,
		alt: 'Newport Folk Festival',
	}, {
		path: CovetrusLogo,
		alt: 'Covetrus',
	}, {
		path: IrobotLogo,
		alt: 'iRobot',
	}, {
		path: AtlanticLogo,
		alt: 'The Atlantic Council',
	}, {
		path: CardinalLogo,
		alt: 'Cardinal Health',
	}, {
		path: CVSLogo,
		alt: 'CVSHealth',
	}, {
		path: GoldmanLogo,
		alt: 'Goldman Sachs',
	}, {
		path: AxiosLogo,
		alt: 'Axios',
	}, {
		path: AudubonLogo,
		alt: 'Audubon',
	}, {
		path: PunchbowlLogo,
		alt: 'Punchbowl News',
	}, {
		path: NasdaqLogo,
		alt: 'Nasdaq',
	},
]

export default function Nascar(props) {
  return (
	<ul class="logo-list mb-64">
	<Fade big>
	{LogoParade.map(logo => (
      	<li><img src={logo.path} alt={logo.alt} /> </li>
      ))} 
	</Fade>
	</ul>
  	)
}

