import React from 'react';
import CaseStudy from '../../components/CaseStudy';
import data from '../../components/App/gallery-data';

const Home = props => {
  return (
      <main>
        <section class="wide-wrapper p3 hp-intro">
          <h1 class="small">Corey Roth</h1>
          <p>I love big thorny design problems and believe in creating inclusive products for everyone. I'm currently a Senior UX Designer at Amazon supporting Seller Infrastructure & Strategic Initiatives.</p>
        </section>
        <section class="case-studies">
      {data.map(whitepaper => (
        <CaseStudy 
          key={whitepaper.name}
          link={whitepaper.link}
          client={whitepaper.client}
          title={whitepaper.title}
          description={whitepaper.description}
          background={whitepaper.background} />
        ))}
      </section>
      </main>  
  );
};

export default Home;