import React, { useEffect } from 'react';
import { useLocation, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import ReactGA from 'react-ga';


//Custom components
import { NavBar } from './components/NavBar';
import Footer from './components/Footer';

//View imports
import { Home } from './views/Home';
import { About } from './views/About';
import { Newport } from './views/Newport';
import { Covetrus } from './views/Covetrus';
import { BoseAR } from './views/BoseAR';
import { Audubon } from './views/Audubon';
import { Goldman } from './views/Goldman';
import { Punchbowl } from './views/Punchbowl';
import { Axios } from './views/Axios';
import { Nasdaq } from './views/Nasdaq';

ReactGA.initialize('UA-57178181-1');

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function ErrorPage() {



  return (
    <section class="main">
      <div class="main-wrapper error">
        <h2 class="mt-0">Page not found.</h2>
        <p>I'm sorry, the page you're looking for can't be found. Try using the navigation above to find what you're looking for. If you think you reached this page in error, <a href="mailto:info@coreyaroth.com?subject=Let's%20collaborate">please contact me</a> and let me know.</p>
      </div>
    </section>
  );
}

export const Routes = () => {
  return (
    <BrowserRouter>
      <NavBar />
        <AnimatedSwitch 
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper">
          <Route exact path="/Home" component={Home} />
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/About" component={About} />
          <Route exact path="/Newport" component={Newport}/>
          <Route exact path="/Covetrus" component={Covetrus}/>
          <Route exact path="/BoseAR" component={BoseAR}/>
          <Route exact path="/Audubon" component={Audubon}/>
          <Route exact path="/Goldman" component={Goldman}/>
          <Route exact path="/Punchbowl" component={Punchbowl}/>
          <Route exact path="/Axios" component={Axios}/>
          <Route exact path="/Nasdaq" component={Nasdaq}/>
          <Route path="*">
            <ErrorPage />
          </Route>
        </AnimatedSwitch>
        <ScrollToTop/>
      <Footer/>
    </BrowserRouter>
  );
};