import React from 'react';

export default function VideoCaption(props) {
  const {alignment, video, caption} = props;
  return(
    <figure class={alignment}>

    <div>
      <video autoplay='autoplay' preload="auto" loop="true" src={video} type="video/webm">
        It looks like your web browser doesn't support the WEBM video format.
      </video>
    </div>
      <figcaption>{caption}</figcaption>
    </figure>
    )
}