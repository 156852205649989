import React from 'react';
import Fade from 'react-reveal/Fade';

export default function HeroBanner(props) {
  const {title, description, background} = props;
  return(
  <figure class="banner tall ">

    <figcaption class="banner-text wide-wrapper">
        <Fade>
          <h1>{title}</h1>
          <p>{description}</p>
        </Fade>
    </figcaption>

    <div class="background-img">
      <img src={background} alt=""/>
    </div>

  </figure>
  )
}
